import emailjs from '@emailjs/browser';
import ComingSoonPage from './ComingSoonPage';

// Initialize EmailJS with your public key
emailjs.init("ota1LCtONBjPN_trI"); // Add your public key here

function App() {
  return <ComingSoonPage />;
}

export default App;
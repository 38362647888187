import { useState } from 'react';
import emailjs from '@emailjs/browser';

const ComingSoonPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await emailjs.send(
        'service_mq1ixr7',
        'template_ifw7hie',
        {
          subscriber_email: email,
          to_email: 'indiefemm@gmail.com'
        },
        'YOUR_PUBLIC_KEY'
      );
      
      setMessage('Thanks for signing up!');
      setEmail('');
    } catch (error) {
      setMessage('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-black relative">
      {/* Brand name */}
      <div className="absolute top-8 left-8 md:left-12">
        <h2 className="text-white text-2xl md:text-3xl font-bold tracking-wider">IndieFemm</h2>
      </div>

      <div className="flex flex-col items-center justify-center min-h-screen p-4 md:p-8">
        <div className="text-center max-w-xl mx-auto">
          {/* Logo placeholder */}
          <div className="mb-8">
            <div className="w-12 h-12 border border-white rounded-full mx-auto flex items-center justify-center">
              <div className="w-6 h-6 border-2 border-white rounded-full"></div>
            </div>
          </div>

          {/* Main content */}
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6 tracking-tight">
            A New Era of Fashion is on Its Way
          </h1>
          
          {/* Description */}
          <div className="mb-12 space-y-6">
            <p className="text-white text-lg md:text-xl font-light leading-relaxed">
              Sustainably Chic—A New Wave of Indian Craft for the Style-Conscious Generation.
            </p>
            <p className="text-gray-300 text-base md:text-lg max-w-lg mx-auto leading-relaxed">
              Get Ready to Experience the Change.
            </p>
          </div>

          {/* Newsletter section */}
          <div className="mb-12">
            <form onSubmit={handleSubmit} className="flex flex-col md:flex-row gap-3 max-w-md mx-auto">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                required
                disabled={isSubmitting}
                className="flex-1 px-4 py-2 rounded-full bg-black text-white placeholder-gray-500 border border-white focus:outline-none focus:border-gray-400 transition-colors disabled:opacity-50"
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className="md:w-auto w-full bg-white text-black px-6 py-2 rounded-full hover:bg-gray-100 transition-colors font-medium disabled:opacity-50"
              >
                {isSubmitting ? 'Signing Up...' : 'Sign Up'}
              </button>
            </form>
          </div>

          {/* Success message */}
          {message && (
            <p className="text-white border border-white p-2 rounded-lg mb-8 max-w-md mx-auto">
              {message}
            </p>
          )}

          {/* Social links */}
          <div className="flex flex-col items-center gap-4">
            <p className="text-gray-300">Follow Our Journey</p>
            <div className="flex gap-8">
              <a 
                href="https://www.instagram.com/indiefemm" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-white hover:text-gray-300 transition-colors"
                aria-label="Follow us on Instagram"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                </svg>
              </a>
              <a 
                href="https://www.facebook.com/indiefemm" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-white hover:text-gray-300 transition-colors"
                aria-label="Follow us on Facebook"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                </svg>
              </a>
              <a 
                href="https://www.linkedin.com/company/indiefemm" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-white hover:text-gray-300 transition-colors"
                aria-label="Follow us on LinkedIn"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                </svg>
              </a>
            </div>
          </div>

          {/* Footer */}
          <div className="mt-16 text-gray-400 text-sm">
            <p>IndieFemm – Rooted in India, Worn by the World</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;